import React, { useState, useEffect } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import CarouselTop from '../../components/HomePage/CarouselTop';
import { GiTrophyCup } from "react-icons/gi";
import { FaLightbulb, FaHandsHelping, FaWhatsapp } from "react-icons/fa";
import { AiOutlineSafety, AiFillWechat } from "react-icons/ai";
import { FaLine } from "react-icons/fa6";
import { Container, Row, Col, Card, Form, Button, Carousel } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';
import FooterFront from '../../components/common/FooterFront';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CopyableText from '../../utils/CopyableText';

const HomePage: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const showCategory = async () => {
    const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, display: { type: 'basic', operator: 'eq', text: true } };
    const res = await DBsearch('systems', 'populate=*', searchStr, 'sort_ASC');
    setCategories(res.info);
  };

  const handleCardClick = (productSlug: string) => (event: React.MouseEvent<HTMLDivElement>) => {
    const urlSlug = productSlug.replace(/\s+/g, '_');
    navigate("/product/" + urlSlug);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const form = document.getElementById('contactForm') as HTMLFormElement | null;
    if (form) {
      form.reset();
    } else {
      console.error('Form not found');
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const name = event.target.formGroupName.value;
    const email = event.target.formGroupEmail.value;
    const subject = event.target.formGroupSubject.value;
    const message = event.target.formGroupMessage.value;

    const mailtoLink = `mailto:jetworld@hibox.hinet.net?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent("Name: " + name + "\nEmail: " + email + "\n\n" + message)}`;

    window.location.href = mailtoLink;
  };

  useEffect(() => {
    showCategory();
  }, []);

  const chunkArray = (array: any[], chunkSize: number) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const categoryChunks = chunkArray(categories, 4);

  return (
    <>
      <NavbarFront />
      <CarouselTop />

      <Container fluid className='section-title'>
        <Row className='pt-5'>
          <Col md={{ span: 8, offset: 2 }} className='pt-4'>
            <h2 className='video-title text-center' data-aos="fade-zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="600"><strong>{t('About Us')}</strong></h2>
            <p className='mt-4 mb-4'>
              {t('index intro')}
            </p>
          </Col>
        </Row>
        <Row className='pt-3 pb-5 justify-content-center'>
          <Col md={{ span: 8 }}>
            <Row>
              {[AiOutlineSafety, GiTrophyCup, FaLightbulb, FaHandsHelping].map((Icon, index) => (
                <Col key={index} data-aos="flip-up" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <Card className='text-center mb-2 card-intro border border-0'>
                    <Card.Body>
                      <div className='icon-circle'>
                        <Icon color='white' size={60} />
                      </div>
                    </Card.Body>
                    <Card.Title>
                      <h4 className='icon-title'>{
                        [t('Integrity'), t('Excellence'), t('Innovation'), t('Mutual Benefit')][index]
                      }</h4>
                    </Card.Title>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className='section-title'>
        <Row className='justify-content-center'>
          <Col md={{ span: 8 }}>
            <Row>
              <Col md={6} className='pb-5'>
                <div className="video-title-container mt-3 mb-2" data-aos="flip-up" data-aos-offset="500" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <span className="vertical-line"></span>
                  <span className="video-title"><h5><strong>{t('Company Introduction Video')}</strong></h5></span>
                </div>
                <video width="100%" controls>
                  <source src="/images/index/video/video3.mp4" type="video/mp4" />
                </video>
              </Col>
              <Col md={6} className='pb-5'>
	<div className="video-title-container mt-3 mb-2" data-aos="flip-up" data-aos-offset="500" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <span className="vertical-line"></span>
                  <span className="video-title"><h5><strong>{t('Exhibition Video')}</strong></h5></span>
                </div>
                <iframe
                  width="100%"
                  height="345"
                  src="https://www.youtube.com/embed/6axjb7Y10zs?si=loUHSXwjS_GVMAky"
                  title="Exhibition Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen>
                </iframe>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className='section-title' style={{ backgroundColor: 'white' }} data-aos="fade-up" data-aos-offset="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('Product')}</strong></h2>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 8 }} className='pb-5'>
            {categories.length > 4 ? (
              <Carousel className="custom-carousel">
                {categoryChunks.map((chunk, chunkIndex) => (
                  <Carousel.Item key={chunkIndex}>
                    <Row>
                      {chunk.map((value: any, index: number) => {
                        const { name_zh, name_en, image } = value.attributes;
                        let imgName;
                        if (image && image.data && image.data.attributes && image.data.attributes.url) {
                          imgName = STRAPI + image.data.attributes.url;
                        }
                        const colProps = index % 2 === 0 ? { md: { offset: 2, span: 4 } } : { md: 4 };

                        return (
                          <Col {...colProps} key={index}>
                            <div className='product-card' role='button' onClick={handleCardClick(name_en)}>
                              <div className='image-container'>
                                <img className="img-fluid mt-5" src={imgName} alt={name_zh} />
                              </div>
                              <div className="product-info">
                                <h5 className='bg-light-orange p-2 rounded'>
                                  {i18n.language === 'en' ? name_en : name_zh}
                                </h5>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <Row>
                {categories.map((value: any, index: number) => {
                  const { name_zh, name_en, image } = value.attributes;
                  let imgName;
                  if (image && image.data && image.data.attributes && image.data.attributes.url) {
                    imgName = STRAPI + image.data.attributes.url;
                  }
                  const colProps = index % 2 === 0 ? { md: { offset: 2, span: 4 } } : { md: 4 };

                  return (
                    <Col {...colProps} key={index}>
                      <div className='product-card' role='button' onClick={handleCardClick(name_en)}>
                        <div className='image-container'>
                          <img className="img-fluid mt-5" src={imgName} alt={name_zh} />
                        </div>
                        <div className="product-info">
                          <h5 className='bg-light-orange p-2 rounded'>
                            {i18n.language === 'en' ? name_en : name_zh}
                          </h5>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Col>
        </Row>
      </Container>

      <Container fluid className='section-title' data-aos="fade-up" data-aos-offset="750" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('Contact Us')}</strong></h2>
          </Col>
        </Row>

        <Row className='justify-content-center mb-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Row>
              <Col md={6} className='mb-2'>
                <Row>
                  <h4>{t('Address')}</h4>
                  <CopyableText text={t('AddressInfo')} tooltipText={`${t('copyText')}${t('Address')}`} />
                  <h4>{t('Phone')}</h4>
                  <CopyableText text="+886-2-2392-5866" tooltipText={`${t('copyText')}${t('Phone')}`} />
                  <h4>{t('Fax')}</h4>
                  <CopyableText text="+886-2-2392-5881" tooltipText={`${t('copyText')}${t('Fax')}`} />
                  <h4>{t('Email')}</h4>
                  <CopyableText text="jetworld@hibox.hinet.net" tooltipText={`${t('copyText')}${t('Email')}`} />
                </Row>
                <div className="d-inline-flex">
                  <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}Line ID`}>
                    <button className='btn btn-outline-dark'>
                      <FaLine size={30} />
                    </button>
                  </CopyableText>&emsp;
                  <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}WeChat ID`}>
                    <button className='btn btn-outline-dark'>
                      <AiFillWechat size={30} />
                    </button>
                  </CopyableText>&emsp;
                  <CopyableText text="+86 18820770402" tooltipText={`${t('copyText')}WhatsApp`}>
                    <button className='btn btn-outline-dark'>
                      <FaWhatsapp size={30} />
                    </button>
                  </CopyableText>
                </div>
              </Col>
              <Col md={6} className='mb-2'>
                <Form id="contactForm" className="gy-4" onSubmit={handleSubmit}>
                  <Form.Group controlId="formGroupName" className='mb-3'>
                    <Form.Label>{t('Your Name')}</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>

                  <Form.Group controlId="formGroupEmail" className='mb-3'>
                    <Form.Label>{t('Your Email')}</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>

                  <Form.Group controlId="formGroupSubject" className='mb-3'>
                    <Form.Label>{t('Subject')}</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>

                  <Form.Group controlId="formGroupMessage" className='mb-3'>
                    <Form.Label>{t('Message')}</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <div className="text-center pt-3">
                    <Button variant="outline-secondary" className='px-5' onClick={handleReset}>{t('clear')}</Button>
                    &emsp;
                    <Button variant='dark' className='px-5' type="submit">{t('send')}</Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <FooterFront />
    </>
  );
};

export default HomePage;